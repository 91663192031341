import { PageProps } from 'gatsby';
import { OrderData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/order.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageBonsCommandeUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, id },
}) => (
  <TemplateEspace
    collectionName="orders"
    docId={id}
    espace={espace}
    user={user}
  >
    <CMSView>
      <Form
        displayHidden
        displayRemove={false}
        displayView
        docId={id}
        itemPathnamePrefix={`/espaces/${espaceId}/orders/`}
        model={
          new OrderData({
            espaceId,
            params,
          })
        }
        name="item"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageBonsCommandeUpdate);
